import React from 'react'
import { Transformation, Video } from 'cloudinary-react'
import { isMobile } from 'react-device-detect'
import './OdometerVideo.scss'

export const VideoApp = ({ autoplay }) => {
  if (process.env.NODE_ENV == 'development' || process.env.NODE_ENV == 'demo') {
    autoplay = false
  }

  return (
    <div className="video-container">
      <Video
        autoplay={autoplay}
        loop
        muted
        className={!isMobile ? 'video-wrapper' : 'video-wrapper-mobile'}
        cloudName="dwtne9urz"
        publicId={'Odometer-howTo/mc-video-july'}
        format="mp4"
        controls
      >
        {isMobile ? (
          <Transformation height="320" width="320" quality="auto" />
        ) : (
          <Transformation quality="auto" fetchFormat="auto" crop="scale" />
        )}
      </Video>
    </div>
  )
}

export default VideoApp
